import React, { useState } from 'react';
import { ErrorMessage } from '~/components/data-entries/ErrorMessage';
import * as styles from './index.styles';

// icons
import EyeInvisible from '~/assets/icon/eye--invisible.svg';
import EyeVisible from '~/assets/icon/eye--visible.svg';

type CommonProps = {
  testId?: string;
  error?: boolean;
  errorMessage?: string;
  description?: string;
  unit?: string;
  width?: number;
  step?: number;
  minDate?: string;
  minNumber?: number;
  maxNumber?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

type CommonTypeProps = {
  type: 'number' | 'text' | 'tel' | 'email' | 'date';
};

type PasswordTypeProps = {
  type: 'password';
  autoComplete: 'current-password' | 'new-password';
};

export type Props = (CommonProps & CommonTypeProps) | (CommonProps & PasswordTypeProps);

export const MESSAGE = {
  ALT_DISPLAY_PASS: '表示',
  ALT_HIDE_PASS: '非表示',
} as const;

export const TESTID = {
  TOGGLE_PASS_BUTTON: 'TOGGLE_PASS_BUTTON',
} as const;

export const Input = React.forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const [showPass, setShowPass] = useState<boolean>(false);
  const toggleShowPass = () => setShowPass(!showPass);

  const typeOfInput = props.type !== 'password' ? props.type : showPass ? 'text' : 'password';
  const {
    error,
    testId,
    errorMessage,
    description,
    minDate,
    minNumber,
    maxNumber,
    ...omittedProps
  } = props;

  return (
    <div css={styles.container({ width: props.width })}>
      {props.unit && <div css={styles.unit}>{props.unit}</div>}
      <input
        {...omittedProps}
        data-testid={testId}
        ref={ref}
        id={props.id}
        name={props.name}
        type={typeOfInput}
        step={props.type === 'number' ? props.step : ''}
        min={props.type === 'number' ? minNumber : props.type === 'date' ? minDate : ''}
        max={props.type === 'number' ? maxNumber : ''}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        css={[
          styles.input({
            readOnly: props.readOnly,
            error: error || !!errorMessage,
          }),
          props.type === 'date' && styles.calendar,
        ]}
        onClick={(e) => {
          if (props.readOnly) return;

          props.onClick?.(e);

          if (props.type === 'date') {
            const date = e.currentTarget as HTMLInputElement;
            date.showPicker?.();
          }
        }}
        onFocus={(e) => {
          if (props.readOnly) return;
          if (props.type === 'number') {
            e.target.addEventListener(
              'wheel',
              (e) => {
                e.preventDefault();
              },
              { passive: false }
            );
          }
        }}
      />

      {props.type === 'password' && (
        <div
          css={styles.eyeContainer.container}
          onClick={toggleShowPass}
          data-testid={TESTID.TOGGLE_PASS_BUTTON}
        >
          <img
            css={styles.eyeContainer.eye}
            src={showPass ? EyeInvisible : EyeVisible}
            alt={showPass ? MESSAGE.ALT_DISPLAY_PASS : MESSAGE.ALT_HIDE_PASS}
          />
        </div>
      )}

      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      {description && <div css={styles.description}>{description}</div>}
    </div>
  );
});
